import React, { useState, useRef, useEffect } from 'react';
import './Cube.css';

const Cube = () => {
  const [rotationAxis, setRotationAxis] = useState('Y');
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const cubeRef = useRef(null);

  const handleClick = () => {
    setRotationAxis(
      rotationAxis === 'Y'
        ? 'X'
        : rotationAxis === 'X'
        ? 'Z'
        : rotationAxis === 'Z'
        ? 'Y'
        : 'X'
    );
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartX(touch.clientX);
    setStartY(touch.clientY);
  };

  const handleTouchMove = (e) => {
    if (startX !== null && startY !== null) {
      const touch = e.touches[0];
      const deltaX = touch.clientX - startX;
      const deltaY = touch.clientY - startY;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        setRotationAxis(deltaX > 0 ? 'Y' : 'Z');
      } else {
        setRotationAxis(deltaY > 0 ? 'X' : 'Z');
      }

      setStartX(touch.clientX);
      setStartY(touch.clientY);
    }
  };

  const handleTouchEnd = () => {
    setStartX(null);
    setStartY(null);
  };

  useEffect(() => {
    const cubeElement = cubeRef.current;
    if (cubeElement) {
      cubeElement.addEventListener('touchstart', handleTouchStart);
      cubeElement.addEventListener('touchmove', handleTouchMove);
      cubeElement.addEventListener('touchend', handleTouchEnd);

      return () => {
        cubeElement.removeEventListener('touchstart', handleTouchStart);
        cubeElement.removeEventListener('touchmove', handleTouchMove);
        cubeElement.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [startX, startY]);

  return (
    <div className="cube-container" onClick={handleClick}>
      <div ref={cubeRef} className={`cube rotate-${rotationAxis}`}>
        <div className="face front">Front-End</div>
        <div className="face back">UX/UI</div>
        <div className="face right">Inbound Marketing</div>
        <div className="face left">Web Design</div>
        <div className="face top">Welcome</div>
        <div className="face bottom">Benvenuto</div>
      </div>
    </div>
  );
};

export default Cube;
