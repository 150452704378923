import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/Farm.module.css'; // Import the CSS module

const Farm = () => {
  const [language, setLanguage] = useState('nl');
  const [selectedProduct, setSelectedProduct] = useState(null); // Track selected product
  const navigate = useNavigate();

  const toggleLanguage = () => {
    setLanguage(prevLanguage => {
      switch (prevLanguage) {
        case 'nl':
          return 'en';
        case 'en':
          return 'jp';
        case 'jp':
          return 'it';
        case 'it':
        default:
          return 'nl';
      }
    });
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const products = [
    {
      id: 'cheese',
      name: {
        nl: 'Kaas',
        en: 'Cheese',
        jp: 'チーズ',
        it: 'Formaggio',
      }[language],
      briefDescription: {
        nl: 'Verken de heerlijke smaken van onze ambachtelijke kazen, gemaakt met de meest verse melk rechtstreeks van onze boerderij.',
        en: 'Explore the exquisite flavors of our artisanal cheeses, crafted with the freshest milk sourced directly from our farm.',
        jp: '私たちの職人のチーズの素晴らしい味わいを探求し、農場から直接取れた新鮮なミルクで作られたチーズをご堪能ください。',
        it: 'Esplora i sapori squisiti dei nostri formaggi artigianali, realizzati con il latte più fresco proveniente direttamente dalla nostra fattoria.',
      }[language],
      fullDescription: {
        nl: 'Onze ambachtelijke kazen worden met trots gemaakt van de beste melk die rechtstreeks van onze boerderij komt. Elke kaas in ons assortiment is liefdevol gemaakt met behulp van traditionele methoden die generaties lang zijn geperfectioneerd, waardoor een smaak ontstaat die echt uitzonderlijk is. Van romige Gouda tot pittige Edam, onze kazen beloven uw smaakpapillen te verrassen bij elke hap. Ervaar het verschil dat melk van de boerderij en eeuwenoude technieken maken - ontdek de ware essentie van Nederlandse kaas bij Kaasboerderij.',
        en: 'Our artisanal cheeses are proudly crafted from the finest milk sourced directly from our farm. Each cheese in our range is lovingly made using traditional methods perfected over generations, ensuring a taste that is truly exceptional. From creamy Gouda to tangy Edam, our cheeses promise to delight your taste buds with every bite. Experience the difference that farm-fresh milk and time-honored techniques make—discover the true essence of Dutch cheese at Cheese Farm.',
        jp: '私たちの職人のチーズは、農場から直接調達した最高のミルクで誇りを持って作られています。私たちのラインナップの各チーズは、世代を超えて完成された伝統的な方法を使用して愛情を込めて作られ、真に優れた味を保証します。クリーミーなゴーダからピリッとしたエダムまで、私たちのチーズは一口ごとに味覚を驚かせることを約束します。農場からの新鮮なミルクと長年の技術がもたらす違いを体験してください。チーズファームでオランダのチーズの真髄を発見してください。',
        it: 'I nostri formaggi artigianali sono orgogliosamente realizzati con il miglior latte proveniente direttamente dalla nostra fattoria. Ogni formaggio nella nostra gamma è amorevolmente preparato utilizzando metodi tradizionali perfezionati nel corso delle generazioni, garantendo un gusto davvero eccezionale. Dal Gouda cremoso all’Edam piccante, i nostri formaggi promettono di deliziare il tuo palato ad ogni morso. Scopri la differenza che fa il latte fresco di fattoria e le tecniche consolidate nel tempo—scopri la vera essenza del formaggio olandese presso la Fattoria del Formaggio.',
      }[language],
      imageUrl: '/assets/images/cheese.jpg',
    },
    {
      id: 'milk',
      name: {
        nl: 'Melk',
        en: 'Milk',
        jp: 'ミルク',
        it: 'Latte',
      }[language],
      briefDescription: {
        nl: 'Geniet van de pure goedheid van verse melk, rechtstreeks van de uiers van onze tevreden koeien.',
        en: 'Indulge in the pure goodness of farm-fresh milk, straight from the udders of our contented cows.',
        jp: '新鮮なミルクの純粋な美味しさをお楽しみください。満足した牛から直接搾られたミルクです。',
        it: 'Goditi la pura bontà del latte fresco di fattoria, direttamente dalle mammelle delle nostre mucche soddisfatte.',
      }[language],
      fullDescription: {
        nl: 'Bij Kaasboerderij zijn we trots op het leveren van de meest verse en hoogwaardige melkproducten, zodat elke slok een heerlijke ervaring is. Van romige volle melk tot voedzame karnemelk, ons assortiment zuivelproducten belooft uw culinaire creaties naar een hoger niveau te tillen en uw lichaam te voeden met de pure goedheid van de natuur.',
        en: 'At Cheese Farm, we take pride in delivering the freshest and highest quality milk products, ensuring that every sip is a delightful experience. From creamy whole milk to wholesome buttermilk, our range of dairy products promises to elevate your culinary creations and nourish your body with the wholesome goodness of nature.',
        jp: 'チーズファームでは、最も新鮮で高品質な乳製品を提供することを誇りに思っています。どんな一口でも、素晴らしい体験を保証します。クリーミーな全乳から栄養豊富なバターミルクまで、私たちの乳製品のラインナップは、料理の創作を高め、自然の純粋な美味しさで身体を養うことを約束します。',
        it: 'Presso la Fattoria del Formaggio, siamo orgogliosi di offrire i prodotti lattiero-caseari più freschi e di alta qualità, assicurando che ogni sorso sia un’esperienza deliziosa. Dal latte intero cremoso al siero di latte nutriente, la nostra gamma di prodotti lattiero-caseari promette di elevare le tue creazioni culinarie e nutrire il tuo corpo con la bontà pura della natura.',
      }[language],
      imageUrl: '/assets/images/milk.jpg',
    },
  ];

  const selectProduct = (productId) => {
    setSelectedProduct(products.find(product => product.id === productId));
  };

  return (
    <div className={styles.farmContainer}>
      <header className={`${styles.header}`} role="banner">
        <button 
          className={styles.languageToggle} 
          onClick={toggleLanguage}
          aria-label={{
            nl: 'Switch to English',
            en: 'Switch to Japanese',
            jp: 'Switch to Italian',
            it: 'Switch to Dutch',
          }[language]}
        >
          {{
            nl: 'English',
            en: '日本語',
            jp: 'Italiano',
            it: 'Nederlands',
          }[language]}
        </button>
        <h1 className={styles.ruralTitle} role="heading" aria-level="1">
          {{
            nl: 'Welkom bij Kaasboerderij',
            en: 'Welcome to Cheese Farm',
            jp: 'チーズファームへようこそ',
            it: 'Benvenuti alla Fattoria del Formaggio',
          }[language]}
        </h1>
      </header>
      <main className={styles.main} role="main">
        <section aria-labelledby="about-us">
          <fieldset className={styles.fieldset}>
            <legend id="about-us">{{
              nl: 'Over ons',
              en: 'About Us',
              jp: '私たちについて',
              it: 'Chi Siamo',
            }[language]}</legend>
            <p>
              {{
                nl: 'Welkom bij Kaasboerderij, een gekoesterd familiebedrijf gelegen in het idyllische landschap van Nederland. Met een rijke geschiedenis die generaties overspant, is onze boerderij toegewijd aan het vervaardigen van de hoogste kwaliteit kaas en melkproducten.',
                en: 'Welcome to Cheese Farm, a cherished family-owned business nestled in the idyllic countryside of the Netherlands. With a rich legacy spanning generations, our farm is dedicated to crafting the highest quality cheese and milk products.',
                jp: 'チーズファームへようこそ。オランダの田園風景に位置する大切な家族経営のビジネスです。何世代にもわたる豊かな歴史を持つ私たちの農場は、最高品質のチーズと乳製品を製造することに専念しています。',
                it: 'Benvenuti alla Fattoria del Formaggio, un prezioso business a conduzione familiare situato nella campagna idilliaca dei Paesi Bassi. Con una ricca eredità che attraversa generazioni, la nostra fattoria è dedicata alla produzione dei formaggi e dei latticini della più alta qualità.',
              }[language]}
            </p>
            <p>
              {{
                nl: 'Gelegen te midden van de pittoreske landschappen van het Nederlandse platteland, is onze boerderij meer dan alleen een bedrijf - het is een manier van leven. Onze toewijding aan traditionele methoden zorgt ervoor dat elk product dat we creëren de authentieke smaak en kwaliteit behoudt die al generaties lang ons kenmerk zijn.',
                en: 'Situated amidst the picturesque landscapes of the Dutch countryside, our farm is more than just a business—it\'s a way of life. Our commitment to traditional methods ensures that each product we create maintains the authentic flavor and quality that has been our hallmark for generations.',
                jp: 'オランダの田園風景に位置する私たちの農場は、単なるビジネス以上のものです。それはライフスタイルです。私たちの伝統的な方法へのコミットメントにより、私たちが作るすべての製品が、何世代にもわたって私たちの特徴である本物の風味と品質を保持しています。',
                it: 'Situata tra i paesaggi pittoreschi della campagna olandese, la nostra fattoria è più di un semplice business: è uno stile di vita. Il nostro impegno verso i metodi tradizionali garantisce che ogni prodotto che creiamo mantenga il sapore autentico e la qualità che è stata il nostro marchio distintivo per generazioni.',
              }[language]}
            </p>
            <p>
              {{
                nl: 'Bij Kaasboerderij zijn we trots op onze erfgoed en zijn we gepassioneerd om de unieke smaak van onze ambachtelijke kazen met u te delen. Van onze familie tot de uwe, nodigen we u uit om de traditie en het vakmanschap te ervaren die ons definiëren.',
                en: 'At Cheese Farm, we take pride in our heritage and are passionate about sharing the unique taste of our artisanal cheeses with you. From our family to yours, we invite you to experience the tradition and excellence that define us.',
                jp: 'チーズファームでは、私たちの遺産を誇りに思い、職人のチーズのユニークな味を共有することに情熱を注いでいます。私たちの家族からあなたの家族へ、私たちを定義する伝統と卓越性を体験するように招待します。',
                it: 'Alla Fattoria del Formaggio, siamo orgogliosi del nostro patrimonio e siamo appassionati di condividere il gusto unico dei nostri formaggi artigianali con te. Dalla nostra famiglia alla tua, ti invitiamo a vivere la tradizione e l’eccellenza che ci definiscono.',
              }[language]}
            </p>
          </fieldset>
        </section>
        <section aria-labelledby="our-products">
          <h2 id="our-products">{{
            nl: 'Onze Producten',
            en: 'Our Products',
            jp: '私たちの製品',
            it: 'I Nostri Prodotti',
          }[language]}</h2>
          <fieldset className={styles.fieldset}>
            <legend>{{
              nl: 'Kaas en Melkproducten',
              en: 'Cheese and Milk Products',
              jp: 'チーズとミルク製品',
              it: 'Formaggi e Prodotti Lattiero-Caseari',
            }[language]}</legend>
            <div className={styles.productContainer}>
              {products.map(product => (
                <div 
                  key={product.id} 
                  className={styles.product} 
                  onClick={() => selectProduct(product.id)}
                  role="button" 
                  tabIndex={0}
                  aria-labelledby={`product-${product.id}`}
                >
                  <img src={product.imageUrl} alt={product.name} />
                  <h3 id={`product-${product.id}`}>{product.name}</h3>
                  <p className={styles.productDescription}>
                    {product.briefDescription}
                  </p>
                </div>
              ))}
            </div>
          </fieldset>
        </section>
        {selectedProduct && (
          <section aria-labelledby="product-details">
            <fieldset className={styles.fieldset}>
              <legend id="product-details">{{
                nl: 'Product Details',
                en: 'Details',
                jp: '商品詳細',
                it: 'Dettagli',
              }[language]}</legend>
              <div>
                <h3>{selectedProduct.name} {selectedProduct.name}</h3>
                <p>
                  {selectedProduct.fullDescription}
                </p>
              </div>
              <button 
                onClick={() => setSelectedProduct(null)} 
                className={styles.backButton}
                aria-label={{
                  nl: 'Terug naar productoverzicht',
                  en: 'Back to product overview',
                  jp: '製品概要に戻る',
                  it: 'Torna alla panoramica dei prodotti',
                }[language]}
              >
                {{
                  nl: 'Terug',
                  en: 'Back',
                  jp: '戻る',
                  it: 'Indietro',
                }[language]}
              </button>
            </fieldset>
          </section>
        )}
        <section aria-labelledby="contact-us">
          <fieldset className={styles.fieldset}>
            <legend id="contact-us">{{
              nl: 'Contact',
              en: 'Contact Us',
              jp: 'お問い合わせ',
              it: 'Contattaci',
            }[language]}</legend>
            <p>
              {{
                nl: 'Heeft u vragen of wilt u een bestelling plaatsen, neem dan gerust contact met ons op!',
                en: 'If you have any questions or would like to place an order, feel free to contact us!',
                jp: 'ご質問がある場合や注文をしたい場合は、お気軽にお問い合わせください。',
                it: 'Se hai domande o desideri effettuare un ordine, non esitare a contattarci!',
              }[language]}
            </p>
            <p>Email: info@cheesefarm.nl</p>
            <p>Phone: +31 123 456 789</p>
          </fieldset>
          <button 
            onClick={handleBack} 
            className={styles.backButton}
            aria-label={{
              nl: 'Terug naar vorige pagina',
              en: 'Back to previous page',
              jp: '前のページに戻る',
              it: 'Torna alla pagina precedente',
            }[language]}
          >
            {{
              nl: 'Terug',
              en: 'Back',
              jp: '戻る',
              it: 'Indietro',
            }[language]}
          </button>
        </section>
      </main>
      <footer className={styles.footer} role="contentinfo">
        <p>&copy; 2024 Proudly powered by Soft<span style={{ color: '#ff6600' }}>D</span>rakula</p>
      </footer>
    </div>
  );
};

export default Farm;
