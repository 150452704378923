import React, { useState, useEffect } from 'react';
import styles from '../css/Clock.module.css';

const Clock = () => {
  const [isDigital, setIsDigital] = useState(true);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(intervalId);
  }, []);

  const toggleClockFormat = () => {
    setIsDigital(!isDigital);
  };

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const formatTime = (date) => {
    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
    };
  };

  const { hours, minutes, seconds } = formatTime(time);

  // Convert hours to 12-hour format and adjust the rotation to correct the time
  const hourDeg = ((hours % 12) * 30) + (minutes * 0.5) - 90;  // Subtract 90 degrees
  const minuteDeg = (minutes * 6) - 90;                        // Subtract 90 degrees
  const secondDeg = (seconds * 6) - 90;                        // Subtract 90 degrees

  return (
    <div className={`${styles.clockContainer} ${isDarkTheme ? styles.darkTheme : styles.lightTheme}`}>
      <div className={styles.clockHeader}>Digital and Analog Clock</div>
      {isDigital ? (
        <div className={`${styles.clock} ${isDarkTheme ? styles.digitalClockDark : styles.digitalClockLight}`}>
          {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
        </div>
      ) : (
        <div className={styles.analogClock}>
          <div className={styles.clockFace}>
            <div
              className={`${styles.hand} ${styles.hourHand}`}
              style={{ transform: `rotate(${hourDeg}deg)` }}
            ></div>
            <div
              className={`${styles.hand} ${styles.minuteHand}`}
              style={{ transform: `rotate(${minuteDeg}deg)` }}
            ></div>
            <div
              className={`${styles.hand} ${styles.secondHand}`}
              style={{ transform: `rotate(${secondDeg}deg)` }}
            ></div>
            <div className={styles.centerDot}></div>
          </div>
        </div>
      )}
      <div className={styles.buttonContainer}>
        <button className={styles.toggleButton} onClick={toggleClockFormat} aria-label="Toggle Clock Format">
          {isDigital ? 'Analog' : 'Digital'}
        </button>
        <button className={styles.themeButton} onClick={toggleTheme} aria-label="Toggle Theme">
          {isDarkTheme ? 'Light Theme' : 'Dark Theme'}
        </button>
        <button className={styles.backButton} onClick={() => window.history.back()} aria-label="Go Back">Back</button>
      </div>
    </div>
  );
};

export default Clock;
