import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Projects.css';

const Projects = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <div className="product-container">
      <header>
        <h1 className="noHoverEffect">Our Portfolio</h1>
      </header>
      <main>
        <div className="main-content">
          <h2>
            Soft<span className="highlight">D</span>rakula
          </h2>
          <h3>Crafting Front-End, Web Design, and UX/UI Solutions</h3>
          <p>Check out some of our projects below...</p>
          <div className="button-container">
            <button
              className="segment-button"
              onClick={() => handleNavigation('/farm')}
              aria-label="Navigate to Kaasboerderij project"
            >
              Kaasboerderij
            </button>
            <button
              className="segment-button"
              onClick={() => handleNavigation('/motorcycle-parts')}
              aria-label="Navigate to MotorcycleParts project"
            >
              MotorcycleParts
            </button>
            <button
              className="segment-button"
              onClick={() => handleNavigation('/clock')}
              aria-label="Navigate to Clock project"
            >
              Clock
            </button>
            <button
              className="segment-button"
              onClick={() => window.location.href = 'https://www.caveirahippie.com'}
              aria-label="Navigate to CaveiraHippie project"
            >
              Caveira Hippie
            </button>
          </div>
        </div>
      </main>
      <footer>
        
        <p>
          &copy; 2024 Proudly powered by Soft<span className="blood">D</span>rakula
        </p>
        <button
          id="backButton"
          onClick={handleBackButton}
          aria-label="Go back to the previous page"
        >
          Back
        </button>
      </footer>
    </div>
  );
};

export default Projects;
