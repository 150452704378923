import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../css/MotorcycleParts.module.css';

const products = [
  { id: 1, name: 'Helmet', description: 'Protective gear for your head.', price: 100, image: 'assets/images/helmet.jpg' },
  { id: 2, name: 'Gloves', description: 'Leather gloves for grip and protection.', price: 50, image: 'assets/images/gloves.jpg' },
  { id: 3, name: 'Jacket', description: 'Protective jacket for safety and style.', price: 200, image: 'assets/images/jacket.jpg' },
  { id: 4, name: 'Boots', description: 'Sturdy boots for riding.', price: 150, image: 'assets/images/boots.jpg' },
  { id: 5, name: 'Goggles', description: 'Eye protection against wind and debris.', price: 30, image: 'assets/images/goggles.jpg' },
  { id: 6, name: 'Exhaust System', description: 'Upgrade your bike\'s performance and sound.', price: 300, image: 'assets/images/exhaust.jpg' }
];

const MotorcycleParts = () => {
  const [cart, setCart] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const navigate = useNavigate();

  const addToCart = (product) => {
    const productElement = document.querySelector(`[data-product-id='${product.id}'] img`);
    if (productElement) {
      const clonedImage = productElement.cloneNode(true);
      clonedImage.classList.add(styles.animateToCart);
      document.body.appendChild(clonedImage);

      const rect = productElement.getBoundingClientRect();

      // Set initial position based on the original image
      clonedImage.style.left = `${rect.left}px`;
      clonedImage.style.top = `${rect.top}px`;
      clonedImage.style.width = `${rect.width}px`;
      clonedImage.style.height = `${rect.height}px`;

      setTimeout(() => {
        clonedImage.classList.add(styles.animate);  // Apply the animation class
      }, 10); // Small delay to trigger the animation

      // After animation ends, remove the image and update the cart
      setTimeout(() => {
        setCart([...cart, product]);
        document.body.removeChild(clonedImage);
      }, 1000); // Match the duration of the animation
    } else {
      setCart([...cart, product]); // Fallback in case animation doesn't work
    }
  };

  const viewProduct = (product) => {
    setSelectedProduct(product);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.cartIconContainer}>
          <Link to="/cart" aria-label="View shopping cart">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm10-4c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM7.01 9l1.2 5h8.7l1.2-5H7.01zm-2-4H1v2h2l3.6 7.59-1.35 2.44C5.11 17.37 5 17.7 5 18c0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12L7.5 15h9.44c.75 0 1.41-.41 1.75-1.03L21.92 7H5.21l-.8-4zM7 18c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45-1 1-1 1-.45 1-1-.45-1-1-1zm10-4c-.55 0-1 .45-1 1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
            </svg>
            <span className={styles.cartCount}>{cart.length}</span>
          </Link>
        </div>
        <h1 className={styles.customTitle}>Ninja Bikes</h1>
        <div className={styles.banner}>
          <p className={styles.slogan}>Your One-Stop Shop for Motorcycle Parts and Accessories</p>
        </div>
      </header>
      <main className={styles.main}>
        {selectedProduct ? (
          <div className={styles.productDetails} role="region" aria-labelledby="product-title">
            <img src={selectedProduct.image} alt={selectedProduct.name} className={styles.productImage} />
            <div className={styles.productContent}>
              <h2 id="product-title">{selectedProduct.name}</h2>
              <p>{selectedProduct.description}</p>
              <p className={styles.price}>Price: €{(selectedProduct.price * 0.85).toFixed(2)}</p>
              <div className={styles.features}>
                <h3>Features:</h3>
                <ul>
                  {selectedProduct.features && selectedProduct.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
              <div className={styles.buttonGroup}>
                <button 
                  className={styles.viewDetailsButton} 
                  onClick={() => addToCart(selectedProduct)} 
                  aria-label={`Add ${selectedProduct.name} to cart`}
                >
                  Add to Cart
                </button>
                <Link to="/cart" className={styles.paymentOptionsButton} aria-label="View cart">View Cart</Link>
                <button className={styles.backButton} onClick={() => setSelectedProduct(null)} aria-label="Back to product list">Back</button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.productList} role="list">
            {products.map(product => (
              <div 
                key={product.id} 
                className={styles.product} 
                role="listitem" 
                data-product-id={product.id} // Add this for targeting in CSS/JS
              >
                <img src={product.image} alt={product.name} className={styles.productImage} />
                <div className={styles.productContent}>
                  <h2>{product.name}</h2>
                  <p>{product.description}</p>
                  <p className={styles.price}>Price: €{(product.price * 0.85).toFixed(2)}</p>
                  <div className={styles.buttonGroup}>
                    <button 
                      className={styles.viewDetailsButton} 
                      onClick={() => viewProduct(product)} 
                      aria-label={`View details of ${product.name}`}
                    >
                      View Details
                    </button>
                    <button 
                      className={styles.paymentOptionsButton} 
                      onClick={() => addToCart(product)} 
                      aria-label={`Add ${product.name} to cart`}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>
      <footer className={styles.footer} role="contentinfo">
        <p>&copy; 2024 Ninja Bikes - Motorcycle Parts and Accessories Store</p>
        <button className={styles.backButton} onClick={handleBackButtonClick} aria-label="Back to previous page">Back</button>
      </footer>
    </div>
  );
};

export default MotorcycleParts;
