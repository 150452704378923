import React from 'react';
import { Link } from 'react-router-dom';
import '../css/About.css';
import blakeImage from '../assets/images/blake-connally-IKUYGCFmfw4-unsplash.jpg';
import headphonesImage from '../assets/images/Headphones001_preview1.png';

const About = () => {
  const BloodSpan = () => <span className="blood">D</span>;

  return (
    <div className="about-page">
      <header>
        <h1>About Us | Soft<BloodSpan />rakula</h1>
      </header>

      <main>
        <section aria-labelledby="about-section">
          <h2 id="about-section">Front-End | Web Design | UX/UI</h2>
          <p>
          "At Soft<BloodSpan />rakula, we craft high-quality, secure, scalable, and accessible front-end and web design solutions, ensuring every project is developed with ARIA for inclusivity."
          </p>
          <p>
              

              Founded by Rafael Simionato, Soft<BloodSpan />rakula was created to develop innovative web projects, from simple websites to complex apps. We offer a wide range of services, including website development, redesigns, and custom themes. Our projects are handpicked, ensuring each one receives the attention it deserves.

              We provide our services without charge, keeping your costs limited to necessary expenses like hosting or domain fees. Our expertise also extends to SEO, Inbound Marketing, and traffic analysis, helping you enhance your online presence effectively.

              Partner with us and elevate your digital presence with tailored design and cutting-edge solutions.
          </p>
          
          <div className="image-container">
            <img src={blakeImage} alt="Blake Connally" loading="lazy" />
          </div>
        </section>

        <section aria-labelledby="choose-us-section">
          <h2 id="choose-us-section">Why Choose Us:</h2>
          <ul>
            <li><strong>Quality and Engagement:</strong> We prioritize high-quality solutions that engage and delight users and customers.</li>
            <li><strong>Responsive Design:</strong> Our responsive layouts adapt seamlessly to various screen sizes using media queries, flexible grids, and responsive images.</li>
            <li><strong>Browser Developer Tools:</strong> We leverage browser developer tools to debug and inspect your code, manipulate the DOM, analyze network requests, and effectively utilize the console.</li>
            <li><strong>Version Control:</strong> Your project code undergoes staged development, allowing you to track progress at each step. We employ Git and GitHub for code management, collaboration, and easy tracking of changes, ensuring real-time customer satisfaction.</li>
            <li><strong>User-Centric Approach:</strong> We focus on user experience. Our applications and systems have user-friendly interfaces, making it easy for your users to navigate and enjoy our web systems.</li>
            <li><strong>Security Matters:</strong> We are consistently vigilant about system security, proactively monitoring for emerging threats and implementing essential precautions to prevent potential breaches.</li>
            <li><strong>Aesthetics and Usability:</strong> We prioritize the aesthetics and user-friendly design of our applications and systems.</li>
          </ul>
        </section>

        <section aria-labelledby="animations-section">
          <h2 id="animations-section">About 3D Animations</h2>
          <blockquote style={{ color: '#ff6600', fontStyle: 'italic' }}>
            "Although we haven't yet embarked on 3D animation projects, we're excited to announce that they are on the horizon. We're gearing up to start developing 3D animation projects in the near future, and we can't wait to dive into this exciting new endeavor."
          </blockquote>
          <div className="image-container">
            <img src={headphonesImage} alt="3D Headphones" loading="lazy" />
          </div>
          <h3>Custom 3D Logos and Branding Animations:</h3>
          <p>- Unique and eye-catching 3D animations of company logos and branding elements for a memorable visual identity.</p>
          <h3>Product Visualization:</h3>
          <p>- Realistic 3D animations of products for marketing and promotional materials. This is particularly useful for industries like manufacturing, retail, and e-commerce.</p>
          <h3>Educational Animations:</h3>
          <p>- Educational animations for e-learning platforms or educational institutions, explaining complex concepts in a visually engaging manner.</p>
          <h3>Medical Animations:</h3>
          <p>- Medical animations for healthcare professionals and organizations to illustrate medical procedures, anatomy, and pharmaceutical mechanisms.</p>
          <h3>Character Design and Animation:</h3>
          <p>- We create unique characters and bring them to life with animations. This can be useful for games, storytelling, or even brand mascots.</p>
          <h3>Social Media Content:</h3>
          <p>- We create attention-grabbing 3D animations for social media marketing campaigns. This could include short promotional videos, GIFs, or interactive content.</p>
          <h3>Event Visualizations:</h3>
          <p>- 3D animations for event planning, showcasing venue layouts, stage designs, and promotional materials for conferences, concerts, or exhibitions.</p>
          <h3>Animated Infographics:</h3>
          <p>- We transform data and information into visually appealing animated infographics. This can be beneficial for businesses looking to present complex data in a more digestible format.</p>
          <h3>Video Game Assets:</h3>
          <p>- 3D models and animations for video game developers, including characters, environments, and special effects.</p>
          <h3>Custom GIFs and Stickers:</h3>
          <p>- Personalized GIFs and stickers for personal or brand use on messaging platforms and social media.</p>
        </section>

        <section aria-labelledby="frameworks-section">
          <h2 id="frameworks-section">Frameworks and Libraries:</h2>
          <ul>
            <li><strong>Front-End Frameworks:</strong> We craft clean code, including Vanilla JavaScript, and also harness popular front-end frameworks and technologies like React, Angular, or Vue.js as needed. Additionally, we utilize back-end frameworks like Laravel where appropriate.</li>
            <li><strong>CSS Preprocessors:</strong> We utilize CSS preprocessors like Sass or Less to produce cleaner and more efficient CSS.</li>
          </ul>
          <h2>Advanced Topics:</h2>
          <ul>
            <li><strong>Performance Optimization:</strong> We employ techniques to optimize website performance, such as minification, lazy loading, and effective caching strategies.</li>
            <li><strong>Accessibility:</strong> Recognizing the importance of inclusivity, we ensure that our projects are accessible to all users, including those with disabilities, by implementing ARIA roles and semantic HTML.</li>
            <li><strong>Web Performance:</strong> We focus on critical rendering paths, reducing render-blocking resources, and optimizing images for the web.</li>
            <li><strong>Web Security:</strong> Our services prioritize security by addressing common vulnerabilities and adhering to best practices, safeguarding your web applications.</li>
          </ul>
        </section>
      </main>

      <footer>
        <p>&copy; 2024 Proudly powered by Soft<BloodSpan />rakula</p>
        <Link to="/">
          <button id="backButton" aria-label="Go back to Home">Back</button>
        </Link>
      </footer>
    </div>
  );
};

export default About;
